<template>
    <div class="loader-container" v-if="isLoading">
        <div class="row justify-content-center align-items-center loader-content">
            <div class="col-md-4 col-xs-4 col-lg-4 text-center">
                <!-- Puedes descomentar la línea siguiente si tienes un logo -->
                <!-- <img src="@/assets/images/emi_logo.png" alt="EMI" class="logo" /> -->
                <div class="spinner"></div>
                <p>Loading...</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Loader",
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-content {
    text-align: center;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.logo {
    width: 55%;
    margin-bottom: 20px;
}

p {
    color: #fff;
    margin-top: 10px;
}
</style>