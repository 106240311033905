var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        } else {
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    //ROL

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //FUERZA

    showFuerza(id) {
        return axios.get('api/Fuerza/show?id=' + id);
    }

    indexFuerza() {
        return axios.defaults.baseURL + 'api/Fuerza/index';
    }

    listFuerza() {
        return axios.get('api/Fuerza/list');
    }

    storeFuerza(item) {
        return axios.post('api/Fuerza/store', item);
    }

    destroyFuerza(item) {
        return axios.post('api/Fuerza/destroy', item);
    }

    //DEPARTAMENTO

    showDepartamento(id) {
        return axios.get('api/Departamento/show?id=' + id);
    }

    indexDepartamento() {
        return axios.defaults.baseURL + 'api/Departamento/index';
    }

    listDepartamento() {
        return axios.get('api/Departamento/list');
    }

    storeDepartamento(item) {
        return axios.post('api/Departamento/store', item);
    }

    destroyDepartamento(item) {
        return axios.post('api/Departamento/destroy', item);
    }

    //ESPECIALIDAD

    showEspecialidad(id) {
        return axios.get('api/Especialidad/show?id=' + id);
    }

    indexEspecialidad() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }

    listEspecialidad() {
        return axios.get('api/Especialidad/list');
    }

    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }

    destroyEspecialidad(item) {
        return axios.post('api/Especialidad/destroy', item);
    }
    //Cargo

    showCargo(id) {
        return axios.get('api/Cargo/show?id=' + id);
    }

    indexCargo() {
        return axios.defaults.baseURL + 'api/Cargo/index';
    }

    listCargo() {
        return axios.get('api/Cargo/list');
    }

    storeCargo(item) {
        return axios.post('api/Cargo/store', item);
    }

    destroyCargo(item) {
        return axios.post('api/Cargo/destroy', item);
    }
    //Reparticion

    showReparticion(id) {
        return axios.get('api/Reparticion/show?id=' + id);
    }

    indexReparticion() {
        return axios.defaults.baseURL + 'api/Reparticion/index';
    }

    listReparticion() {
        return axios.get('api/Reparticion/list');
    }

    storeReparticion(item) {
        return axios.post('api/Reparticion/store', item);
    }

    destroyReparticion(item) {
        return axios.post('api/Reparticion/destroy', item);
    }
    //Escalafon

    showEscalafon(id) {
        return axios.get('api/Escalafon/show?id=' + id);
    }

    indexEscalafon() {
        return axios.defaults.baseURL + 'api/Escalafon/index';
    }

    listEscalafon() {
        return axios.get('api/Escalafon/list');
    }

    storeEscalafon(item) {
        return axios.post('api/Escalafon/store', item);
    }

    destroyEscalafon(item) {
        return axios.post('api/Escalafon/destroy', item);
    }
    //Grado

    showGrado(id) {
        return axios.get('api/Grado/show?id=' + id);
    }

    indexGrado() {
        return axios.defaults.baseURL + 'api/Grado/index';
    }

    listGrado() {
        return axios.get('api/Grado/list');
    }

    storeGrado(item) {
        return axios.post('api/Grado/store', item);
    }

    destroyGrado(item) {
        return axios.post('api/Grado/destroy', item);
    }

    //Sexo

    showSexo(id) {
        return axios.get('api/Sexo/show?id=' + id);
    }

    indexSexo() {
        return axios.defaults.baseURL + 'api/Sexo/index';
    }

    listSexo() {
        return axios.get('api/Sexo/list');
    }

    storeSexo(item) {
        return axios.post('api/Sexo/store', item);
    }

    destroySexo(item) {
        return axios.post('api/Sexo/destroy', item);
    }

    //GrupoSanguineo

    showGrupoSanguineo(id) {
        return axios.get('api/GrupoSanguineo/show?id=' + id);
    }

    indexGrupoSanguineo() {
        return axios.defaults.baseURL + 'api/GrupoSanguineo/index';
    }

    listGrupoSanguineo() {
        return axios.get('api/GrupoSanguineo/list');
    }

    storeGrupoSanguineo(item) {
        return axios.post('api/GrupoSanguineo/store', item);
    }

    destroyGrupoSanguineo(item) {
        return axios.post('api/GrupoSanguineo/destroy', item);
    }

    //EstadoCivil

    showEstadoCivil(id) {
        return axios.get('api/EstadoCivil/show?id=' + id);
    }

    indexEstadoCivil() {
        return axios.defaults.baseURL + 'api/EstadoCivil/index';
    }

    listEstadoCivil() {
        return axios.get('api/EstadoCivil/list');
    }

    storeEstadoCivil(item) {
        return axios.post('api/EstadoCivil/store', item);
    }

    destroyEstadoCivil(item) {
        return axios.post('api/EstadoCivil/destroy', item);
    }

    //Arma

    showArma(id) {
        return axios.get('api/Arma/show?id=' + id);
    }

    indexArma() {
        return axios.defaults.baseURL + 'api/Arma/index';
    }

    listArma() {
        return axios.get('api/Arma/list');
    }

    storeArma(item) {
        return axios.post('api/Arma/store', item);
    }

    destroyArma(item) {
        return axios.post('api/Arma/destroy', item);
    }
}