<script>
const sidebarMenu = [

  { path: '/Profile', icon: 'fa fa-medkit', title: 'Mi Perfil'},

  { path: '/Persona', icon: 'fa fa-medkit', title: 'Paramétricas',
    children: [
      { path: '/Persona', title: 'Persona' },
      { path: '/Departamento', title: 'Departamento' },
      { path: '/Fuerza', title: 'Fuerza' },
      { path: '/Especialidad', title: 'Especialidad' },
      { path: '/Sexo', title: 'Sexo' },
      { path: '/GrupoSanguineo', title: 'Grupo Sanguineo' },
      { path: '/EstadoCivil', title: 'Estado Civil' },
      { path: '/Escalafon', title: 'Escalafon' },
      { path: '/Cargo', title: 'Cargo' },
      { path: '/Grado', title: 'Grado' },
      { path: '/Reparticion', title: 'Reparticion' },
      { path: '/Arma', title: 'Arma' }
    ]
  },

  // { path: '/', icon: 'fa fa-medkit', title: 'Login'},
]

export default sidebarMenu;
</script>
