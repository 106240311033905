<template>
	<div>
		<div id="header" class="header navbar navbar-default navbar-expand-lg navbar-fixed-top">
			<div class="container">
				<div class="row">
				<h3 class="header-title">Calendario Agrícola <small>admin.</small></h3>

				</div>
				<hr class="bg-grey-lighter" />
				<div class="row">
					<div class="col-lg-2" style="margin-top: 40px;">
						<ul>
							<li v-for="event in events" :key="event.id">{{ event.title }} - {{ event.start }}</li>
						</ul>
					</div>
					<div class="col-lg-10" style="margin-top: 40px;">
						<!-- Ajusta el valor del margin-top según sea necesario -->
						<full-calendar :events="events" :config="config"></full-calendar>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		var fecha = new Date();
		var anioActual = fecha.getFullYear();
		var mesActual = fecha.getMonth() + 1;
		mesActual = (mesActual < 10) ? '0' + mesActual : mesActual;

		return {
			events: [{
				title: 'Evento de todo el día',
				start: anioActual + '-' + mesActual + '-01',
				color: '#00ACAC'
			}, {
				title: 'Evento largo',
				start: anioActual + '-' + mesActual + '-07',
				end: anioActual + '-' + mesActual + '-10'
			}, {
				id: 999,
				title: 'Evento repetido',
				start: anioActual + '-' + mesActual + '-09T16:00:00',
				color: '#00ACAC'
			}],
			config: {
				defaultView: 'month',
				header: {
					left: 'month,agendaWeek,agendaDay',
					center: 'title',
					right: 'prev,today,next '
				}
			}
		}
	}
}
</script>